@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@600&display=swap");
iframe#webpack-dev-server-client-overlay {
  display: none;
}
.filterIcon {
  -webkit-filter: brightness(10) !important;
  filter: brightness(10) !important;
}
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 375px) {
  .overflowMobilee {
    height: 262px !important;
    overflow: auto !important;
  }
}
@media only screen and (max-width: 900px) {
  #scrollableDiv {
    height: 70vh !important;
  }
  .gridBoxesCostume {
    grid-template-columns: repeat(auto-fill, minmax(109px, 1fr)) !important;
  }
  .mobileBoxs {
    width: 5rem !important;
    height: 5rem !important;
  }
  section.mobileNav {
    overflow: auto !important;
    height: 200px !important;
  }
  html {
    overflow: hidden;
  }
  .changeInputWidth {
    width: 100% !important;
  }
  .mobileHidden {
    display: none !important;
  }
  .sidebar {
    display: none !important;
  }
}

:where(.vi__container) {
  position: relative;
  display: flex;
  gap: 8px;
  height: 41px !important;
  width: 249px !important;
}

:where(.vi__character) {
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  font-size: 18px !important;
  line-height: 46px !important;
  color: black;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px !important;
  cursor: default;
  user-select: none;
  box-sizing: border-box;
}

/* .toast-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 9999 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.toast-content {
  position: relative !important;
  z-index: 10000 !important; 
} */
/* .Toastify {
  background: rgba(0, 0, 0, 0.6) !important;
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  z-index: 99999 !important;
  top: 0 !important;
  left: 0 !important;
} */

.tutorial {
  position: fixed;
}
/* .transition-all.duration-300.ease-in-out.fixed.top-0.left-0.z-\[1040\].bg-black.w-screen.h-screen.block.opacity-50 {
  z-index: 99999 !important;
  background: rgba(0, 0, 0, 1) !important;
} */

.numberCircle {
  /* width: 70px;
  height: 70px; */
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  text-align: center;
  padding: 0.8rem;
  font-size: 21.6px;
  border: 2px solid #1e6dd5;
  color: white;
  background: #1e6dd5;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 900px) {
  .mdMobile {
    margin-bottom: 4rem !important;
  }
  .dashboradtEST {
    height: 200px;
 
  }

  .modalDilog {
    margin: 1.1rem 0.8rem 0.8rem 0.8rem !important;
    height: calc(100vh - 149px) !important;
  }
  /* .Toastify__toast-container.Toastify__toast-container--bottom-right {
    bottom: 19px !important;
  } */
  .Toastify__toast-container.Toastify__toast-container--bottom-right {
    bottom: 93px !important;
}
  /* .maxContainerDashboard {
    height: 75vh !important;
    overflow: auto !important;
    display: flex !important;
    align-items: self-start !important;
    align-content: start !important;
  } */
  .flex.flex-col.fixed.inset-0.portalPopupOverlay.overlayModal {
    z-index: 1253637384849938383 !important;
    position: absolute !important;
  }
  .iekbcc0.ju367va.ju367v1s {
    display: flex;
    gap: 2rem;
    justify-content: space-around !important;
    width: 100%;
  }
  /* .iekbcc0.ju367va.ju367v1s {
    width: 100% !important;
    display: flex;
    justify-content: space-around !important;
  }
  .iekbcc0.ju367vs.ju367v2a.ju367v6c.ju367v8r.ju367v93 {
    background: transparent !important;
  } */
  /* button.iekbcc0.iekbcc9.ju367v76.ju367v7r.ju367v8b.ju367v6k.ju367v4.ju367va3.ju367vn.ju367vei.ju367vfu.ju367vb.ju367va.ju367v16.ju367v1h.ju367v1p.ju367v8u._12cbo8i3.ju367v8r._12cbo8i4._12cbo8i6,
  button.iekbcc0.iekbcc9.ju367v4.ju367va3.ju367vn.ju367vei.ju367vfu.ju367va.ju367v16.ju367v1h.ju367v8u._12cbo8i3.ju367v8r._12cbo8i4._12cbo8i6 {
    width: 100% !important;
  } */
  .tutorial.open {
    /* top: 150px; */
    height: 100vh;
    top: 32vw;
    width: 100%;
    display: block;
  }
}
@media only screen and (max-width: 900px) {
  .fixedHeightMobile {
    height: 32px !important;
    margin: auto !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .flex-colMob {
    flex-direction: column !important;
  }
  .mobileMinWidth {
    min-width: auto !important;
  }
  .mobileChangess {
    overflow-x: hidden !important;
    padding: 0rem !important;
  }
  .mobMargin {
    margin: 0rem 1rem !important;
  }
}
.iekbcc0.ju367v9.ju367v6,
.iekbcc0.ju367v8 {
  font-size: 14px !important;
}
@media only screen and (min-width: 1700px) {
  .maxContainer {
    max-width: 1440px;
    margin: auto;
  }
  .maxHeightDesktop {
    height: 600px !important;
  }
}
.tutorial.open {
  right: 0;
  display: block;
}
@media only screen and (min-width: 1200px) {
  .modalDilog {
    /* width: 72% !important; */
    /* height: 100vh !important; */
    overflow-y: auto !important;
    /* margin-left: 20% !important; */
  }
  .flex-rowDesk {
    flex-direction: row !important;
  }
}
.tooltipAccount {
  width: 300px !important;
  z-index: 9999 !important;
}

button.iekbcc0.iekbcc9.ju367v6x.ju367v7i.ju367v93.ju367vn.ju367vdr.ju367vec.ju367v11.ju367v1c.ju367v2b.ju367v8i._12cbo8i3.ju367v8g._12cbo8i4._12cbo8i6 {
  padding-left: 22px !important;
  padding-right: 22px !important;
  font-size: 13px !important;
}

.containerData {
  display: grid;
  grid-template-columns: 1fr 80px;
  gap: 8px;
  /* if you want some max-width, put it here instead*/
  max-width: 500px;
}

.wrap-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 8px;
}

.item {
  height: 80px;
  background-color: blue;
  color: white;
}

.non-wrap-item {
  height: 80px;
  color: white;
}

.grid {
  display: grid;
  padding-right: 20px;
  grid-column-gap: 19px;
  grid-row-gap: 17px;
  grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
}

.grid > * {
  column-gap: 0.5rem;
}
#googleIcon span {
  display: none !important;
}

.contentModsl {
  height: auto !important;
  overflow-y: auto;
}

.modalDilog {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}

/* .infinite-scroll-component {
  overflow: hidden !important;
} */

#signinDiv button {
  /* Hide the text content */
  text-indent: -9999px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.tutorial {
  transition: left 0.3s ease-in-out;
}
.tutorial-toggle {
  position: absolute;
  top: 20%;
  right: -60px;
  height: 60px;
  transition: width 2s;
  width: 60px;
  z-index: 1;
}

.border-ColorWhite {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

button.iekbcc0.iekbcc9.ju367v78.ju367v7t.ju367v9i.ju367vn.ju367vei.ju367vf3.ju367v16.ju367v1h.ju367v2g.ju367v8u._12cbo8i3.ju367v8r._12cbo8i4._12cbo8i6 {
  font-size: 14px !important;
}

.gridDetails {
  grid-column-gap: 6px !important;
  grid-row-gap: 6px !important;
}
img.n1UuX-DkfjY {
  display: none !important;
}
.nsm7Bb-HzV7m-LgbsSe-BPrWId {
  display: none !important;
}
/* .reactGooglelogin div div div div {
  min-width: 59px !important;
}

.reactGooglelogin div div div div div div div {
  padding: 0px !important;
  margin: 0px !important;
}
.reactGooglelogin svg {
  width: 33px !important;
  margin-top: -39px !important;
} */
/* .reactGooglelogin svg {
  width: 33px !important;
  margin-top: -19px !important;
}

.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe.hJDwNd-SxQuSe.i5vt6e-Ia7Qfc.uaxL4e-RbRzK {
  border: none !important;
} */

svg.LgbsSe-Bz112c {
  width: 27px !important;
  margin-top: -13px !important;
  margin-left: -4px !important;
}

.facebooks {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background-color 0.218s, border-color 0.218s;
  transition: background-color 0.218s, border-color 0.218s;
  -webkit-user-select: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dadce0;
  color: #3c4043;
  cursor: pointer;
  font-family: "Google Sans", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 4px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
}
ul {
  list-style: unset !important;
  margin: revert !important;
  padding: revert !important;
}

.infinite-scroll-component {
  overflow: hidden !important;
}
